
@font-face {
  font-family: "Soehne-Leicht";
  src: url("./fonts/soehne/soehne-web-leicht.woff") format('woff');
}

@font-face {
  font-family: "Soehne-XLeicht";
  src: url("./fonts/soehne/soehne-web-extraleicht.woff") format('woff');
}

@font-face {
  font-family: "Soehne-Kraftig";
  src: url("./fonts/soehne/soehne-web-kraftig.woff") format('woff');
}

body{
  font-family: "Soehne-Leicht";
  text-transform: uppercase;
  padding-bottom:100px;
}


.app {
  text-align: center;
}

a{
  text-decoration: none;
  color:#000;
}

p{
  padding:20px;
}

header {
  padding:10px 0;
  background-color: #fff;
  position: fixed;
  bottom:0;
  width:100%;
  z-index: 1;
}

header ul{
  display: flex;
  gap:10px;
  justify-content: center;
  margin:15px 0;
  text-transform: uppercase;
}

.navigation{
  display: flex;
  justify-content: center;
  gap:10px;
}

.logo {
  width:12%;
  display: block;
  margin:0 auto;
}

.app-link {
  color: #61dafb;
}

ul{
  list-style: none;
  padding:0;
}

.clients-list{
  padding:0 400px;
  display: flex;
  flex-wrap: wrap;
}

.client-logo{
  object-fit: cover;
  width: 25%;
  vertical-align: middle;
  object-position: center;
}

.client-logo img{
  width:55%;
  display: block;
  margin: 0 auto;
}


.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.image-gallery > a {
  width:30%;
  cursor: pointer;
  position: relative;
}

.image-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 300px;
  vertical-align: middle;
  object-position: center;
}

.single-gallery-container .image-gallery li img{
  height:100%;
}

.single-gallery-container .image-gallery li {
  width: 30%;
}

.philantrophy{
  padding:0 400px;
  text-align: center;
}

.kg-logo{
  width:50%;
  display: block;
  margin: 0 auto;
}

.str-logo{
  width:50%;
  display: block;
  margin: 0 auto;
  filter: invert(1);
}



@media screen and (max-width: 600px){
  body{
    padding-bottom: 200px;
  }
  header{
    padding:20px 0;
    bottom:0;
  }
  .logo {
    width:40%;
  }

  .client-logo{
    width: 33%;
  }

  .client-logo img{
    width:50%;
  }

  .image-gallery > a {
    width:90%;
    height: 100%;
  }
  
  
  .image-gallery li img {
    height:100%;
  }

  .single-gallery-container .image-gallery li {
    width:100%;
  }

  .philantrophy, .clients-list{
    padding:0;
  }
}